module.exports = function (Vuex) {
  return {
    install (Vue) {
      var temp = Vuex.Store.prototype.dispatch
      Vuex.Store.prototype.httpDispatch = function (options) {
        let that = this
        return function () {
          // options: $loading options
          if (options) {
          }
          return temp.apply(that, arguments).then(resObj => {
            if (!resObj.data) {
              resObj.data = {
                resType: 'error',
                code: '5000000',
                msg: 'Internal server error'
              }
            }
            return resObj
          }).catch((err) => {
            throw err.response
          })
        }
      }
    }
  }
}
