<template>
  <div>
    <div id="target" class="result">
      <el-card>
        <el-select style="width: 110px" v-model="locale" >
          <el-option
            label="简体中文"
            value="zh" />
          <el-option
            label="English"
            value="en" />
        </el-select>
        <el-row :gutter="20" class='info' >
          <h1>{{TextMap[locale].title}}</h1>
          <el-col style="text-align: left" :span="18" :offset="3">
            <h3>{{TextMap[locale].info1}}</h3>
            <p>{{TextMap[locale].info2}}</p><br/>
            <p>{{TextMap[locale].info3}}</p><br/>
            <p>{{TextMap[locale].info4}}</p><br/>
            <h3>{{TextMap[locale].infoulTitle}}</h3>
            <ul>
              <li>{{TextMap[locale].infoli1}}</li>
              <li>{{TextMap[locale].infoli2}}</li>
              <li>{{TextMap[locale].infoli3}}</li>
            </ul>
          </el-col>
        </el-row>
      </el-card>
      <div v-if="fundus.imageUrl">
        <el-card >
          <el-row :gutter="20">
            <el-button v-if="fundus.imageUrl" @click="reStart" style="margin: 10px auto 10px">{{TextMap[locale].btnText}}</el-button>
          </el-row>
          <el-row :gutter="20">
            {{TextMap[locale].timeConsuming}} {{time}} s
          </el-row>
        </el-card>
        <el-card>
          <el-row :gutter="20">
            <el-col :span="8">
              <span class="demonstration">Input image</span>
              <el-image
                :src="fundus.imageUrl"
                :preview-src-list="[fundus.imageUrl]">
              </el-image>
            </el-col>
            <el-col :span="8">
              <span class="demonstration">Segmentation</span>
              <el-image
                :src="fundus._seg"
                :preview-src-list="[fundus._seg]">
              </el-image>
            </el-col>
            <el-col :span="8">
              <span class="demonstration">AV crossing</span>
              <!-- <br/> -->
              <el-image
                :src="fundus._av_crossing"
                :preview-src-list="[fundus._av_crossing]">
              </el-image>

              <!-- <el-image>
                <div style="margin-top: 100px;" slot="error" class="image-slot">
                  <span style="font-size: 20px">under development</span>
                </div>
              </el-image> -->
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <span class="demonstration">A/V ratio</span>
              <el-image
                :src="fundus._disc"
                :preview-src-list="[fundus._disc]">
              </el-image>
            </el-col>
            <el-col :span="8">
              <span class="demonstration">Artery</span>
              <el-image
                :src="fundus._artery"
                :preview-src-list="[fundus._artery]">
              </el-image>
            </el-col>
            <el-col :span="8">
              <span class="demonstration">Vein</span>
              <el-image
                :src="fundus._vein"
                :preview-src-list="[fundus._vein]">
              </el-image>
            </el-col>
          </el-row>
          <el-row>
            <img style="width: 100%" src="@/assets/vessel_info.png" alt="">
          </el-row>
        </el-card>
        <el-card>
          <el-row class="demonstration">
            Egdes
          </el-row>
          <el-table
            border
            :data="edgesData"
            :span-method="arraySpanMethod"
            :show-header="false"
            stripe>
            <el-table-column
              v-for="(label, index) in fundus.edgesResult.tableData && fundus.edgesResult.tableData[0]"
              :label="label"
              :key="index.toString()"
              :prop="index.toString()">
            </el-table-column>
          </el-table>
        </el-card>
        <el-card>
          <el-row class="demonstration">
            Nodes
          </el-row>
          <el-table
            border
            :data="nodesData"
            :show-header="false"
            :span-method="arraySpanMethod"
            stripe>
            <el-table-column
              v-for="(label, index) in fundus.nodesResult.tableData && fundus.nodesResult.tableData[0]"
              :label="label"
              :key="index.toString()"
              :prop="index.toString()">
            </el-table-column>
          </el-table>
        </el-card>
        <el-card>
          <el-row>
            measurements edges csv download url: <a :href="fundus.measurements_edges">{{fundus.measurements_edges.split('/').pop()}}</a>
          </el-row>
          <el-row>
            measurements nodes csv download url: <a :href="fundus.measurements_nodes">{{fundus.measurements_nodes.split('/').pop()}}</a>
          </el-row>
          <el-row>
            reports edges csv download url: <a :href="fundus.reports_edges">{{fundus.reports_edges.split('/').pop()}}</a>
          </el-row>
          <el-row>
            reports nodes csv download url: <a :href="fundus.reports_nodes">{{fundus.reports_nodes.split('/').pop()}}</a>
          </el-row>
        </el-card>
      </div>
      <el-card v-else>
        <Upload :time="time" :max_time="max_time" :setTime="setTime" :clearTimer="clearTimer"  :msg="TextMap[locale].dragText" :decript="TextMap[locale].decript" />
      </el-card>
      <el-card>
        <el-row :gutter="20" class='about' >
          <el-col style="text-align: left" :span="18" :offset="3">
            <h1>{{TextMap[locale].aboutTitle}}</h1>
            <h2>{{TextMap[locale].aboutt1}}</h2>
            <p>{{TextMap[locale].aboutp1_1}}</p>
            <img src="@/assets/mrHe.png" alt="">
            <p>{{TextMap[locale].aboutp1_2}}</p>
            <img style="margin-left: 20px;" src="@/assets/msShi.jpeg" alt="">
            <h3>{{TextMap[locale].aboutt2}}</h3>
            <p>{{TextMap[locale].aboutp2_1}}<a href="mailto:mingguanghe@gmail.com"> mingguanghe@gmail.com</a>   </p>
            <h3>{{TextMap[locale].aboutt3}}</h3>
            <p>{{TextMap[locale].aboutp3_1}}</p>
          </el-col>
        </el-row>

      </el-card>
    </div>
  </div>
</template>

<script>
import Upload from './Upload.vue'
import { mapState } from 'vuex'
import _ from 'lodash'
export default {
  components: {
    Upload
  },
  data() {
    return {
      resultUrl: '',
      locale: 'en',
      time: 0,
      max_time: 60,
      langMap: {
        en: 'English',
        zh: '简体中文'
      },
      TextMap: {
        zh: {
          timeConsuming: '耗时:',
          timeoutText: '预测失败，请上传其他眼底图片',
          dragText: '将文件拖到此处，或单击上传',
          btnText: '重新开始',
          title: "视网膜血管自动标注平台",
          decript: '（请从眼底照相机上传原始图像，支持JPEG，PNG，TIF，BMP格式）',

          aboutt1: "发明者",
          aboutt2: "许可和合作机会",
          aboutp2_1: "这项技术可用于研究和教育目的。如有疑问，请联系",
          info1: '总览',
          info2: '基于视网膜的微血管健康评估系统（RMHAS）是由何明光教授和他的博士生施丹莉开发的软件。RMHAS集成了由研究团队设计的深度学习神经网络，并使用大量内部数据集和多个公共数据集进行了训练/验证。它旨在主要从正常眼睛分析图像，但也可以处理具有病理变化的病例。',
          info3: 'RMHAS使用GPU功能，并以全自动方式完成对指定文件夹中图像的分析。分析完成后，软件将输出带注释的图像以进行准确性检查，并输出带有所有单独测量值的几个CSV文件。',
          info4: "对于打算大量分析眼底照片的研究人员，RMHAS是一个有用的工具。该网站为用户提供了上载图像的机会，以体验该软件的基本测量功能。",
          infoulTitle: '主要功能',
          infoli1: '质量评估',
          infoli2: '动脉和静脉和视盘的分割和分类',
          infoli3: '标准区域和整个眼底的措施（16个基本参数） ',

          aboutt1: 'Developer',
          aboutp1_1: '何明光教授, MD PhD FRANZCO',
          aboutp1_2: '施丹莉 MD',
          aboutt2: '询问合作 ',
          aboutp2_1: "我们打算提供RMHAS软件，作为一种研究和教育工具，只要适当地认可该软件，所有研究人员都可以免费使用。 如果任何研究人员对合作感兴趣，或有其他意见或反馈，请联系:",
          aboutt3: '免责声明',
          aboutp3_1: '本网站仅用于研究目的。 RMHAS研究人员和开发人员对使用任何后果均不承担任何责任。'
        },
        en: {
          timeConsuming: 'time consuming:',
          timeoutText: 'Measurement failed, please upload other fundus images',
          btnText: 'ReStart',
          dragText: 'Upload image session',
          decript: '(Please upload raw images from fundus camera, supports JPEG, PNG, TIF, BMP format)',
          title: 'Retina-based Microvascular Health Assessment System（RMHAS）',
          info1: 'Overview',
          info2: 'Retina-based Microvascular Health Assessment System（RMHAS）is a software developed by Prof. Mingguang He and his Ph.D. student Dr. Danli Shi. RMHAS integrates a deep learning neural network designed by the research team and trained/validated using a sizeable in-house dataset and multiple public datasets. It intends to analyze images primarily from normal eyes but can also handle cases with pathological changes.',
          info3: 'RMHAS uses GPU power and completes analysis for images in a designated folder in a fully automatic manner. When analyses finish, the software outputs annotated images for accuracy check and several CSV files with all individual measurements.',
          info4: "RMHAS is a helpful tool for researchers who intend to analyze fundus photographs in a large quantity. This website offers users an opportunity to upload images to experience the software's basic measurement features. ",
          infoulTitle: 'Modules',
          infoli1: 'Quality assessment',
          infoli2: 'Artery, Vein, Optic disc segmentation',
          infoli3: 'Measures in the Standard zone and whole fundus (16 basic parameters)',

          aboutt1: 'Developer',
          aboutp1_1: 'Prof. Mingguang He, MD PhD FRANZCO',
          aboutp1_2: 'Dr. Danli Shi MD',
          aboutt2: 'Inquiry for collaboration',
          aboutp2_1: 'We intend to provide RMHAS software as a research and educational tool free of charge for all researchers so long as the software is appropriately acknowledged. Should any researchers be interested in the collaboration, or other comments or feedback, please contact:',
          aboutt3: 'Disclaimer',
          aboutp3_1: 'This website is for research purposes solely. RMHAS researchers and developers do not hold any responsibility for any consequence whatsoever of its use. '
        }
      }
    }
  },
  computed: { ...mapState({
      fundus: state => state.fundus.fundus
    }),
    nodesData: function () {
      let data = _.cloneDeep(this.fundus.nodesResult.tableData)
      data.shift()
      data.unshift(['Vtype', 'Branching Coefficient', '', 'Asymmetry Ratio', '', 'Junctional Exponent Deviation', '', 'Angular Asymmetry', ''])
      data.map((dt) => {
        dt.map((d, index) =>{
          if (d === 'A' & d !== 'V') {
            dt[index] = 'Artery'
          }
          if (d === 'V' & d !== 'A') {
            dt[index] = 'Vein'
          }
        })
      })
      return data
    },
    edgesData: function () {
      let data = _.cloneDeep(this.fundus.edgesResult.tableData)
      data.shift()
      data.unshift(['Vtype', 'Arc', '', 'Chord', '', 'Branching Angle', '', 'BA_edge', '', 'Mean diameter', '', 'Tortuosity', '', 'Length Diameter Ratio' , '' ])
      data.map((dt) => {
        dt.map((d, index) =>{
          if (d === 'A' & d !== 'V') {
            dt[index] = 'Artery'
          }
          if (d === 'V' & d !== 'A') {
            dt[index] = 'Vein'
          }
        })
      })
      return data
    },
  },
  methods: {
    reStart () {
      this.$store.commit('SET_FUNDUS', {
        fundus: {}
      })
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // if (rowIndex === 0) {
        if (columnIndex === 0) {
          return [1, 1]
        } else if ((columnIndex % 2) !== 0) {
          return [1, 2]
        } else {
          return [0, 0]
        }
      // }
    },
    setTime () {
      this.time = 0
      this.timer = setInterval(() => {
        this.time ++
        if (this.time >= this.max_time) {
          alert(this.TextMap[this.locale].timeoutText)
          this.clearTimer()
          this.reStart()
          return
        }
      }, 1000)
    },
    clearTimer () {
      clearInterval(this.timer)
    },
  }
}
</script>
<style lang="less">
.result {
  position: relative;
  .el-select {
    float: right;
    position: relative;
    top: -20px;
    right: -150px;
    z-index: 1;
  }
  .info {
    li, h2, h3 {
      text-align: left;
    }
    h1 {
      margin-bottom: 15px;
    }
    h3 {
      margin-top: 15px;
      font-size: 15px;
    }
    li {
      font-size: 14px;
    }
  }
  .demonstration {
    font-size: 20px;
  }
  .el-card {
    .el-table .cell {
      font-size: 11px;
    }
    box-sizing: border-box;
    width: 1200px;
    margin: 30px auto;
    overflow: inherit;
  }
  .el-image {
  }
}
.about {
  text-align: left;
    line-height: 3rem;
  img {
    width: 150px;
  }
  ul {
    li {
      font-size: 14px;
    }
  }
}
</style>