<template>
  <div class="home">
    <Result />
  </div>
</template>

<script>
// @ is an alias to /src
import Result from '../components/Result.vue'

export default {
  name: 'Home',
  components: {
    Result
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="less">
.home {
  padding-bottom: 20px;
  .home-box {
    position: relative;
    padding: 20px 30px;
    min-height: 520px;
    width: 800px;
    margin: 20px auto 0;
    box-sizing: border-box;
  }
  .home-btn {
    position: absolute;
    bottom: 200px;
    left: 50%;
    width: 250px;
    height: 80px;
    font-size: 30px;
    margin-left: -125px;
  }
}
</style>