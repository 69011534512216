
import api from '@/utils/api'
const state = {
  fundus: {
  }
}
const getters = {
  getFundus (state) {
    return state
  }
}

const actions = {
  setFundus (contaxt, fundus) {
    contaxt.commit('SET_FUNDUS', fundus)
  }
}

const mutations = {
  SET_FUNDUS (state, { fundus }) {
    state.fundus = fundus
  }
}

export default {
  state,
  mutations,
  getters,
  actions
}
